<template>
  <vca-field
    :label="
      $t('profile.change.header') +
      ' (' +
      $t('profile.change.created') +
      ' ' +
      date(user.modified.created) +
      ')'
    "
  >
    <p>{{ $t("profile.change.description") }}</p>
    <button class="vca-button" @click="navigateToLogin()">
      {{ $t("profile.change.email") }}
    </button>
    <button class="vca-button" @click="navigateToLogin()">
      {{ $t("profile.change.password") }}
    </button>
    <button class="vca-button" @click="navigateToLogin()">
      {{ $t("profile.change.name") }}
    </button>

    <p>
      {{ $t("profile.edit.profile.delete.description") }}
    </p>
    <br />

    <div class="vca-center">
      <vca-cancel-button
        @click="deleteProfile()"
        :placeholder="$t('button.delete')"
      />
    </div>
  </vca-field>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AccountChange",
  methods: {
    navigateToLogin() {
      window.location.href =
        process.env.VUE_APP_LOGIN_URL +
        "/profile/?callback=" +
        window.btoa(window.location.href);
    },
    deleteProfile() {
      if (!confirm(this.$t("profile.edit.profile.delete.confirm"))) {
        return false;
      }
      this.$store.commit("suppressMsg", true);
      this.$store.commit("users/current", this.user);
      this.$store
        .dispatch({
          type: "users/deleteReq",
        })
        .then(() => {
          this.$store.commit("suppressMsg", false);

          this.notification({
            title: "messages.title.success",
            body: this.$t("messages.user.account_deleted"),
            type: "success",
          });
          setTimeout(() => {
            this.$router.push("/logout");
          }, 5000);
        });
    },
  },
  computed: {
    ...mapGetters({
      user: "user/current",
    }),
  },
};
</script>
