<template>
  <div>
    <p v-if="canDownloadCert">
      {{ $t("profile.certificate.desc.allowed") }}
    </p>
    <p v-else>{{ $t("profile.certificate.desc.denied") }}</p>
    <vca-dropdown
      v-if="canDownloadCert"
      v-model="locale.selected"
      :options="locale.values"
      label=""
      :placeholder="$t('profile.certificate.language')"
    ></vca-dropdown>
    <div>
      <button class="vca-button" v-if="canDownloadCert" @click="download">
        {{ $t("button.download") }}
      </button>
    </div>
    <CertificateDownload ref="certificate" :locale="getLocale" />
  </div>
</template>

<script>
import CertificateDownload from "./CertificateDownload.vue";
import { mapGetters } from "vuex";
export default {
  name: "Certificate",
  components: { CertificateDownload },
  data() {
    return {
      locale: {
        values: [
          {
            title: this.$t("locale.de.name"),
            label: this.$t("locale.de.name"),
            value: "de",
          },
          {
            title: this.$t("locale.en.name"),
            label: this.$t("locale.en.name"),
            value: "en",
          },
        ],
        selected: [{ value: "de" }],
      },
    };
  },
  methods: {
    download() {
      this.$refs.certificate.download();
    },
  },
  computed: {
    getLocale() {
      return this.locale.selected[0].value;
    },
    ...mapGetters({
      isActive: "user/active/isActive",
      profile: "user/profile/current",
      user: "user/current",
    }),
    canDownloadCert() {
      return (
        this.isActive && this.isLongTimeSupporter && this.profile.birthdate
      );
    },
    isLongTimeSupporter() {
      var now = Date.now();
      // 6 months
      const difference = 178 * 24 * 60 * 60;
      return now / 1000 - this.user.modified.created >= difference;
    },
  },
};
</script>
