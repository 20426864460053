<template>
  <div>
    <vca-row>
      <vca-card class="shadowed">
        <vca-field :label="$t('profile.settings.header_member')">
          <ActiveFlag />
          <br />
          <NonvotingMembership />
        </vca-field>
        <vca-field :label="$t('profile.certificate.header')">
          <Certificate />
        </vca-field>
      </vca-card>
      <vca-card class="shadowed">
        <vca-field :label="$t('profile.settings.header_roles')">
          <Roles />
        </vca-field>
        <Change />
      </vca-card>
    </vca-row>
  </div>
</template>

<script>
import Change from "@/components/account/settings/Change";
import NonvotingMembership from "@/components/account/settings/NonvotingMembership";
import ActiveFlag from "@/components/account/settings/ActiveFlag";
import Certificate from "@/components/account/settings/Certificate";
import Roles from "@/components/account/settings/Roles";
export default {
  name: "AccountSettings",
  components: {
    Change,
    NonvotingMembership,
    ActiveFlag,
    Roles,
    Certificate,
  },
};
</script>
